@layer reset, globals, base, tokens, recipes, utilities, overrides;

@layer globals {
  html,
  body,
  * {
    box-sizing: border-box;
    font-family: 'Plus Jakarta Sans', sans-serif;
    -webkit-font-smoothing: initial;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #f8f7f2;
  }

  html {
    scroll-padding-top: 56px;
  }
}

@layer overrides {
  .shine {
    background: linear-gradient(to right, #666363 0, black 10%, #666363 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: animate 3s linear infinite;
  }
  @keyframes animate {
    0% {
      background-position: 0;
    }
    50% {
      background-position: 470px;
    }
    100% {
      background-position: 470px;
    }
  }
}
